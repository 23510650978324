import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Layout from '../components/layout';

const Articles = () => {
	const data = useStaticQuery(
		graphql`
			query {
                allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
                    totalCount
                    edges {
                        node {
                            id
                            frontmatter {
                                title
                                date(formatString: "DD MMMM, YYYY")
                            }
                            fields {
                                slug
                            }
                            excerpt
                        }
                    }
                }
            }
		`
    )
    return (
        <Layout>
            {data.allMarkdownRemark.edges.map(({ node }) => (
                <section key={node.id}>
                    <Link to={node.fields.slug}>
                        <h1 className="title is-1">{node.frontmatter.title}</h1>
                    </Link>
                    <span className="is-italic">{node.frontmatter.date}</span>
                    <p className="is-size-4">{node.excerpt} <Link to={node.fields.slug}>Lire la suite</Link></p>  
                </section>
            ))}
        </Layout>
    )
};

export default Articles;